import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
//@ts-ignore
import CatalogueStyles from "../../Styles.module.scss";
import Filter from "./Filter.tsx";
import CandidateCard from "./CandidateCard.tsx";
import DetailCandidateParent from "./DetailCandidateParent.tsx";
import Pagination from "./Pagination.tsx";
import Carousel from "react-elastic-carousel";
import { ArrowLeftSharp, ArrowRightSharp } from "@material-ui/icons";
import { v4 as uuid } from "uuid";
//@ts-ignore
import _ from "lodash";
import { Context } from "react";
import LoaderContext, { LoaderontextType } from "./LoaderContext.tsx";
import { CANDIDATE_BASE_URL } from "../../helper/config.js";
import Loader from "../loader/Loader.jsx";
import axios from "axios";
import { toast } from "react-toastify";

const configJSON = require("./config");

const breakPoints = [
  { width: 600, itemsToShow: 1 },
  { width: 900, itemsToShow: 2 },
  { width: 1000, itemsToShow: 3 },
];

const carouselStyle = (theme: any) => ({
  carousel: {
    "& button": {
      backgroundColor: "#FFF",
    },

    "& .rec-arrow": {
      width: "2em",
      height: "2em",
      minWidth: "2em",
      lineHeight: "1em",
      fontSize: "0.8em",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  },
});

interface Props {
  navigation: any;
  id: string;
  match?: { params: { id: string | number } };
  location?: any;
  history?: any;
  classes?: any;
}

interface State {
  snackBar: {
    show: boolean;
    message: string;
    type: any;
  };
  currentIndex: number;
  maxItems: number;
  windowWidth: number;
  databaseList: any;
  totalDatabaseCount: number;
  totalPages: number;
  defaultPages: number;
  selectedDBData: any;
  showDatabaseData: boolean;
  onPageLoad: boolean;
  suggesionType: string;
  locationList: any;
  titleList: any;
  companyList: any;
  keywordsList: any;
  eSParams: any;
  loading: boolean;
  minExperience: number | string;
  maxExperience: number | string;
  location: string;
  skills: string[];
  job_description: string;
  keywords: string[];
  advanceSearch: boolean;
  role: string[];
  dataNotFoundError: string;
}

const notifySuccess = (success) => {
  toast.dismiss();
  toast.success(success, {
    position: "top-center",
    autoClose: 3000,
  });
};

const notifyError = (error) => {
  toast.dismiss();
  toast.error(error, {
    position: "top-center",
    autoClose: 3000,
  });
};

class CandidateDatabase extends React.Component<Props, State> {
  static contextType?: Context<LoaderontextType> = LoaderContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      snackBar: {
        show: false,
        message: "",
        type: "",
      },
      currentIndex: 0,
      maxItems: 4,
      windowWidth: window.innerWidth,
      databaseList: [],
      totalDatabaseCount: 0,
      totalPages: 1, // Change it to 0 Later
      defaultPages: 1,
      selectedDBData: {},
      showDatabaseData: true,
      onPageLoad: true,
      suggesionType: "",
      locationList: null,
      titleList: null,
      companyList: null,
      keywordsList: [],
      eSParams: {},
      loading: false,
      minExperience: "",
      maxExperience: "",
      location: "",
      skills: [],
      job_description: "",
      keywords: [],
      advanceSearch: false,
      role: [],
      dataNotFoundError: "",
    };

    this.checkWindowResize = this.checkWindowResize.bind(this);
    this.getDatabaseList = this.getDatabaseList.bind(this);
    this.watchedCandidateData = this.watchedCandidateData.bind(this);
    this.elasticSearch = this.elasticSearch.bind(this);
    this.isDataEmpty = this.isDataEmpty.bind(this);
    this.getFilterSuggessionList = this.getFilterSuggessionList.bind(this);
    this.openSnackBarHandler = this.openSnackBarHandler.bind(this);
    this.closeSnackBarHandler = this.closeSnackBarHandler.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener("resize", this.checkWindowResize);
    window.scrollTo(0, 0);
    this.setState({ loading: true }, () => this.elasticSearch(0));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkWindowResize);
  }

  checkWindowResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  openSnackBarHandler(
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  }

  closeSnackBarHandler() {
    this.setState({
      snackBar: {
        show: false,
        message: this.state.snackBar.message,
        type: this.state.snackBar.type,
      },
    });
  }

  async getDatabaseList(page: number) {
    try {
      const header: any = {
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${CANDIDATE_BASE_URL}${configJSON.getDatabaseListAPiEndPoint}?page=${page}`,
        {
          method: configJSON.apiMethodTypeGet,
          headers: header,
        }
      );

      const responseJson = await response.json();
      if (responseJson !== undefined && !responseJson?.errors) {
        let validCandidate = responseJson?.data?.filter((item: any) =>
          this.isDataEmpty(item.attributes)
        );

        this.setState({
          databaseList: validCandidate ? [...validCandidate] : [],
          totalDatabaseCount:
            responseJson?.meta?.total_profile.toLocaleString() || "0",
          totalPages: responseJson?.meta?.total_pages || 0,
          advanceSearch: false,
        });
        if (this.state.onPageLoad) {
          this.setState({
            selectedDBData: validCandidate
              ? responseJson?.data[0]?.attributes
              : {},
            // onPageLoad: false
          });
        } else {
          this.setState({
            showDatabaseData: false,
          });
        }
      } else {
        const errors = responseJson?.errors;
        if (errors) {
          notifyError(errors || "facing issues");
        }
        // throw new Error("facing issues");
      }
    } catch (error) {
      notifyError(error.message || "facing issues");
      this.setState({ loading: false });
    }
  }

  async watchedCandidateData(id: any) {
    const header = {
      "Content-Type": "application/json",
    };

    const httpBody = {
      temporary_user_database_id: id,
      ip_address: localStorage.getItem("currentIPA"),
    };

    await fetch(
      CANDIDATE_BASE_URL + "/" + configJSON.watchedCandidateDataAPiEndPoint,
      {
        method: configJSON.apiMethodTypePost,
        headers: header,
        body: JSON.stringify(httpBody),
      }
    );
  }

  async elasticSearch(page: any) {
    try {
      let currentIPA: any = localStorage.getItem("currentIPA");
      if (!currentIPA) {
        currentIPA = uuid();
        localStorage.setItem("currentIPA", currentIPA);
      }

      const header: any = {
        "Content-Type": "application/json",
        Token: sessionStorage.getItem("Token"),
      };

      let httpBody: any = { ...this.state.eSParams, page: page };
      if (this.state.eSParams?.current == "Current") {
        httpBody["current"] = true;
      } else if (this.state.eSParams?.current == "Past") {
        httpBody["current"] = false;
      } else {
        delete httpBody["current"];
      }
      if (this.state.eSParams?.watched == 'Show "Watched"') {
        httpBody["watched"] = true;
      } else if (this.state.eSParams?.watched == 'Exclude "Watched"') {
        httpBody["watched"] = false;
      } else {
        delete httpBody["watched"];
      }
      httpBody["ip_address"] = currentIPA;

      const response = await fetch(
        CANDIDATE_BASE_URL + configJSON.getDatabaseListAPiEndPoint,
        {
          method: configJSON.apiMethodTypePost,
          headers: header,
          body: JSON.stringify(httpBody),
        }
      );

      const responseJson = await response.json();
      this.setState({ loading: false });
      if (responseJson !== undefined && !responseJson?.errors) {
        let validCandidate = responseJson?.data;
        this.setState({
          databaseList: validCandidate ? validCandidate : [],
          totalDatabaseCount:
            responseJson?.meta?.total_profile.toLocaleString() || "0",
          totalPages: responseJson?.meta?.total_pages || 0,
          advanceSearch: false,
        });
        if (this.state.onPageLoad) {
          this.setState({
            selectedDBData: validCandidate
              ? responseJson?.data[0]?.attributes
              : {},
          });
        } else {
          this.setState({
            showDatabaseData: false,
          });
        }
      } else {
        const errors = responseJson?.errors;
        if (errors) {
          notifyError(errors || "facing issues");
        }
      }
    } catch (error) {
      notifyError(error.message || "facing issues");
      this.setState({ loading: false });
    }
  }

  // async advanceSearch(page: any) {
  //   this.setState({ loading: true });
  //   let currentIPA: any = localStorage.getItem("currentIPA");
  //   if (!currentIPA) {
  //     currentIPA = uuid();
  //     localStorage.setItem("currentIPA", currentIPA);
  //   }

  //   const header = {
  //     "Content-Type": "application/json",
  //     Token: sessionStorage.getItem("Token"),
  //   };

  //   let httpBody = {
  //     minExperience: this.state.minExperience
  //       ? `${this.state.minExperience}`
  //       : undefined,
  //     maxExperience: this.state.maxExperience
  //       ? `${this.state.maxExperience}`
  //       : undefined,
  //     skills: this.state.skills,
  //     location: this.state.location,
  //     job_description: this.state.job_description,
  //     keywords: this.state.keywords,
  //     roles: this.state.role,
  //   };

  //   try {
  //     const response = await axios.post(
  //       `${CANDIDATE_BASE_URL}${configJSON.advanceSearchApiEndPoint}?page=${page}`,
  //       httpBody,
  //       { headers: header }
  //     );

  //     const responseJson = response.data;
  //     this.setState({ loading: false });
  //     if (responseJson && !responseJson.errors) {
  //       this.setState({
  //         databaseList: responseJson.data,
  //         totalDatabaseCount:
  //           responseJson.meta?.total_record?.toLocaleString() || "0",
  //         totalPages: responseJson.meta?.total_pages || 0,
  //         advanceSearch: true,
  //       });
  //       if (this.state.onPageLoad) {
  //         this.setState({
  //           selectedDBData: responseJson.data[0]
  //             ? responseJson.data[0].attributes
  //             : {},
  //           // onPageLoad: false
  //         });
  //       } else {
  //         this.setState({
  //           showDatabaseData: false,
  //         });
  //       }
  //     } else {
  //       const errors = responseJson.errors;
  //       if (errors) {
  //         // notifyError(errors || "facing issues");
  //         this.setState({
  //           dataNotFoundError: "Data Not Found",
  //           databaseList: [],
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     this.setState({ loading: false });
  //     this.setState({ dataNotFoundError: "Data Not Found", databaseList: [] });
  //   }
  // }

  async advanceSearch(page: any) {
    this.setState({ loading: true });

    let currentIPA: any = localStorage.getItem("currentIPA");
    if (!currentIPA) {
      currentIPA = uuid();
      localStorage.setItem("currentIPA", currentIPA);
    }

    const header = {
      "Content-Type": "application/json",
      Token: sessionStorage.getItem("Token"),
    };

    let httpBody = {
      location: this.state.location,
      keywords: `${this.state.role[0].replace(
        " ",
        " AND "
      )} And (${this.state.skills
        .map((skill) => `"${skill}"`)
        .join(" OR ")
        .replace("``", "")})`,
      ip_address: currentIPA,
      company: "",
      title: "",
      page: page,
      experience: {
        started: this.state.minExperience
          ? Number(this.state.minExperience)
          : undefined,
        ended: this.state.maxExperience
          ? Number(this.state.maxExperience)
          : undefined,
      },
    };

    try {
      const response = await axios.post(
        `${CANDIDATE_BASE_URL}${configJSON.getDatabaseListAPiEndPoint}`,
        JSON.stringify(httpBody),
        { headers: header }
      );

      const responseJson = response.data;
      this.setState({ loading: false });
      if (responseJson && !responseJson.errors) {
        this.setState({
          databaseList: responseJson.data,
          totalDatabaseCount:
            responseJson.meta?.total_record?.toLocaleString() || "0",
          totalPages: responseJson.meta?.total_pages || 0,
          advanceSearch: true,
        });
        if (this.state.onPageLoad) {
          this.setState({
            selectedDBData: responseJson.data[0]
              ? responseJson.data[0].attributes
              : {},
            // onPageLoad: false
          });
        } else {
          this.setState({
            showDatabaseData: false,
          });
        }
      } else {
        const errors = responseJson.errors;
        if (errors) {
          // notifyError(errors || "facing issues");
          this.setState({
            dataNotFoundError: "Data Not Found",
            databaseList: [],
          });
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      this.setState({ dataNotFoundError: "Data Not Found", databaseList: [] });
    }
  }

  isDataEmpty(candidate: any) {
    if (
      _.isEmpty(candidate.full_name) ||
      _.isEmpty(candidate.temporary_user_profile)
    ) {
      return false;
    } else {
      return true;
    }
  }

  async getFilterSuggessionList(type: string, searchText: string) {
    try {
      const header: any = {
        "Content-Type": "application/json",
        Token: sessionStorage.getItem("Token"),
      };

      const response = await fetch(
        `${CANDIDATE_BASE_URL}${configJSON.getFilterSuggessionListAPiEndPoint}?${type}=${searchText}`,
        {
          method: configJSON.apiMethodTypeGet,
          headers: header,
        }
      );

      const responseJson = await response.json();

      if (responseJson !== undefined && !responseJson?.errors) {
        if (type === "location") {
          this.setState({ locationList: responseJson?.sugg });
        } else if (type === "title") {
          this.setState({ titleList: responseJson?.sugg });
        } else if (type === "company") {
          this.setState({ companyList: responseJson?.sugg });
        } else if (type === "keyword") {
          this.setState({ keywordsList: responseJson?.sugg });
        }
      } else {
        const errors = responseJson?.errors;
        if (errors) {
          this.openSnackBarHandler("error", errors);
        }
      }
    } catch (error) {
      notifyError(error.message || "facing issues");
      this.setState({
        locationList: [],
        titleList: [],
        companyList: [],
        keywordsList: [],
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Box className={CatalogueStyles.continerWrapper}>
          <Grid container spacing={1}>
            <Grid
              item
              xl={3}
              lg={3}
              md={3}
              sm={12}
              xs={12}
              className={CatalogueStyles.containerItems}
            >
              {/* Database filter component */}
              <Filter
                onSearch={(state) => {
                  let allPrams: any = {
                    location: state.location,
                    title: state.title,
                    company: state.company,
                    current: state.cp,
                    watched: state.status,
                  };
                  if (state.selectedOption === "keyword") {
                    allPrams["keywords"] = state.keyword_OR_name;
                  } else {
                    allPrams["full_name"] = state.fullName;
                  }

                  if (
                    state.years_of_exp_to === "" &&
                    state.years_of_exp_from !== ""
                  ) {
                    allPrams["experience"] = {
                      started:
                        state.years_of_exp_from > 98
                          ? 0
                          : state.years_of_exp_from,
                      ended: 99,
                    };
                  } else if (
                    state.years_of_exp_from === "" &&
                    state.years_of_exp_to !== ""
                  ) {
                    allPrams["experience"] = {
                      started: 0,
                      ended:
                        state.years_of_exp_to > 99 ? 99 : state.years_of_exp_to,
                    };
                  } else if (
                    state.years_of_exp_from !== "" &&
                    state.years_of_exp_to !== ""
                  ) {
                    allPrams["experience"] = {
                      started:
                        state.years_of_exp_from > 98
                          ? 0
                          : state.years_of_exp_from,
                      ended:
                        state.years_of_exp_to > 99 ? 99 : state.years_of_exp_to,
                    };
                  }
                  this.setState(
                    {
                      loading: true,
                      onPageLoad: true,
                      defaultPages: 1,
                      eSParams: {
                        ...allPrams,
                      },
                    },
                    () => this.elasticSearch(0)
                  );
                }}
                locationList={this.state.locationList}
                companyList={this.state.companyList}
                titleList={this.state.titleList}
                keywordsList={this.state.keywordsList}
                onSuggessionSearch={this.getFilterSuggessionList}
                onReset={() => {
                  this.setState(
                    {
                      loading: true,
                      onPageLoad: true,
                      eSParams: {},
                      advanceSearch: false,
                      defaultPages: 0,
                    },
                    () => this.elasticSearch(0)
                  );
                }}
                onAdvanceSearch={(state) => {
                  this.setState(
                    {
                      minExperience: state.minExperience,
                      maxExperience: state.maxExperience,
                      location: state.location,
                      keywords: state.keywords,
                      job_description: state.job_description,
                      skills: state.skills,
                      role: state.role,
                      defaultPages: 1,
                    },
                    () => this.advanceSearch(0)
                  );
                }}
              />
            </Grid>
            {!this.state.loading ? (
              this.state.databaseList && this.state.databaseList.length > 0 ? (
                <>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={3}
                    sm={12}
                    xs={12}
                    className={CatalogueStyles.containerItemsSecond}
                    style={
                      this.state.totalPages === 1
                        ? { marginTop: "1.7em" }
                        : { marginTop: "0.5em" }
                    }
                  >
                    {/* Candidate card Pagination handler */}
                    <Pagination
                      totalPages={this.state.totalPages}
                      defaultPages={this.state.defaultPages}
                      onPageNumSelect={(page: any) => {
                        this.setState(
                          {
                            loading: true,
                            onPageLoad: true,
                            defaultPages: page,
                          },
                          () => {
                            this.state.advanceSearch
                              ? this.advanceSearch(page - 1)
                              : this.elasticSearch(page - 1);
                          }
                        );
                      }}
                    />
                    {/* Candidate cards for screen size >= 960 */}
                    {this.state.windowWidth >= 960 ? (
                      <Box className={CatalogueStyles.candidatecardListWrap}>
                        {this.state.databaseList?.map(
                          (candidate: any, index: any) => {
                            return (
                              <CandidateCard
                                key={index}
                                candidateData={candidate?.attributes}
                                isLoggedIn={false}
                                marginBottom={2}
                                showEmptyImg={false}
                                isSelectedCan={
                                  candidate?.attributes?.temporary_user_profile
                                    ?.id ==
                                  this.state.selectedDBData
                                    ?.temporary_user_profile?.id
                                }
                                sendSelectedCandidateData={(data) => {
                                  window.scroll(0, 0);
                                  let currentList = [
                                    ...this.state.databaseList,
                                  ];
                                  let newList = currentList.map((item) =>
                                    candidate?.id == item?.id
                                      ? {
                                          ...item,
                                          attributes: {
                                            ...item.attributes,
                                            watched_records: true,
                                          },
                                        }
                                      : { ...item }
                                  );
                                  this.setState({
                                    selectedDBData: data,
                                    databaseList: [...newList],
                                  });
                                  this.watchedCandidateData(candidate?.id);
                                }}
                              />
                            );
                          }
                        )}
                      </Box>
                    ) : (
                      //@ts-ignore
                      <Carousel
                        isRTL={false}
                        breakPoints={breakPoints}
                        className={classes.carousel}
                      >
                        {/* Candidate cards in Catousel /Slider for screen size < 960 */}
                        {this.state.databaseList?.map(
                          (candidate: any, index: any) => {
                            return (
                              <CandidateCard
                                key={index}
                                candidateData={candidate?.attributes}
                                isLoggedIn={false}
                                marginBottom={2}
                                showEmptyImg={false}
                                sendSelectedCandidateData={(data) => {
                                  let currentList = [
                                    ...this.state.databaseList,
                                  ];
                                  let newList = currentList.map((item) =>
                                    candidate?.id == item?.id
                                      ? {
                                          ...item,
                                          attributes: {
                                            ...item?.attributes,
                                            watched_records: true,
                                          },
                                        }
                                      : { ...item }
                                  );
                                  this.setState({
                                    selectedDBData: data,
                                    databaseList: [...newList],
                                  });
                                  this.watchedCandidateData(candidate?.id);
                                }}
                              />
                            );
                          }
                        )}
                      </Carousel>
                    )}
                  </Grid>
                  <Grid
                    item
                    xl={5}
                    lg={5}
                    md={6}
                    sm={12}
                    xs={12}
                    className={CatalogueStyles.containerItemsSecond}
                  >
                    <Box className={CatalogueStyles.count}>
                      <Typography component="span">
                        Total Profiles ~ {this.state.totalDatabaseCount}
                      </Typography>
                    </Box>
                    {this.state.selectedDBData ? (
                      <DetailCandidateParent
                        candidateData={this.state.selectedDBData}
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                </>
              ) : (
                <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                  <Box className={CatalogueStyles.noDataFound}>
                    <Typography variant="h5">No Data Found</Typography>
                  </Box>
                </Grid>
              )
            ) : (
              <Loader />
            )}
          </Grid>
        </Box>
      </>
    );
  }
}

export default withStyles(carouselStyle)(CandidateDatabase);
